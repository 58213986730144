<template>
  <v-container align-center justify-center>
    <v-layout row wrap justify-center>
      <v-img
        src="@/assets/images/logo.jpg"
        max-height="190"
        max-width="150"
      ></v-img>
    </v-layout>
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3>
        <v-card color="primary">
          <v-container fluid grid-list-sm>
            <v-layout row wrap class="layout">
              <v-flex xs6 v-if="showOthers" @click="goTo('shipping/dashboard')">
                <div class="text-action justify-center">Dashboard</div>
              </v-flex>
              <v-flex
                xs6
                @click="goTo('shipping/checkin/type')"
                class="justify-center checkin"
              >
                <div class="text-action">CheckIn</div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";

export default {
  name: "home",
  methods: {
    goTo(route) {
      this.$router.push({ path: route });
    },
  },
  computed: {
    ...mapGetters(["user"]),
    showOthers() {
      if (this.user == null) return false;
      else return this.user.role != "Driver";
    },
  },
};
</script>

<style>
.layout {
  cursor: pointer;
  color: white;
  font-size: x-large;
}
.text-action {
  width: min-content;
  margin: 0 auto;
}

.text-action:hover {
  text-shadow: 2px 2px 4px #000000;
}

.checkin {
  margin: 0 auto;
}
</style>
